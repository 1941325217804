@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    overscroll-behavior: none !important;
}

body {
    background-color: black;
    font-family: "Fugaz One", sans-serif;
    overflow-x: hidden;
    scrollbar-gutter: stable;
}

img {
    pointer-events: none;
}

.hide-scrollbar {
    /* For Internet Explorer, Edge */
    -ms-overflow-style: none;

    /* For Firefox */
    scrollbar-width: none;

}

/* For Chrome, Safari, and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Optional: Customize the scrollbar appearance */
::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: #000000;
}

::-webkit-scrollbar-thumb {
    /*background: #21C55D;*/
    background: #ffffff40;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    /*background: #1daf52;*/
}

.spinner {
    height: 1em;
    width: 1em;
    border: 1px solid #FFFFFF20;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: #FFFFFF50;
    animation: rotate 1.2s linear infinite;
    box-sizing: border-box;
}

.spinner-dark {
    height: 1em;
    width: 1em;
    border: 1px solid #00000020;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: #00000050;
    animation: rotate 1.2s linear infinite;
    box-sizing: border-box;
}


@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes shake {
    0%, 100% {
        transform: rotate(0);
    }
    10%, 30%, 50%, 70% {
        transform: rotate(-10deg);
    }
    20%, 40%, 60%, 80% {
        transform: rotate(10deg);
    }
    90% {
        transform: rotate(-5deg);
    }
}

.shake-element {
    animation: shake 4s ease-in-out infinite;
}


.float-animation {
    animation: float 3s ease-in-out infinite;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-4px);
    }
}

.tooltip-container {
    background-color: black !important;
    border: 1px solid #ffffff20 !important;
    color: white !important;
}

@keyframes grain {
    0%, 100% {
        background-position: 0 0;
    }
    10% {
        background-position: -5% -10%;
    }
    20% {
        background-position: -15% 5%;
    }
    30% {
        background-position: 7% -25%;
    }
    40% {
        background-position: -20% 20%;
    }
    50% {
        background-position: 15% -15%;
    }
    60% {
        background-position: 0 25%;
    }
    70% {
        background-position: -25% 10%;
    }
    80% {
        background-position: 5% 20%;
    }
    90% {
        background-position: -10% -10%;
    }
}

.grainy-effect::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    pointer-events: none;
    background-image: url('../src/img/noisy-background-crop.jpg');
    opacity: 0.085;
    transform: scale(1);
    background-repeat: repeat;
    animation: grain 0.5s steps(10) infinite;
}


@keyframes growSpinShrink {
    0% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(1.25) rotate(720deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}

.grow-spin-shrink {
    animation: growSpinShrink 6s ease-in-out infinite;
}


@keyframes shakeAnimation {
    0%, 100% {
        transform: rotate(0deg);
    }
    5%, 15%, 25%, 35% {
        transform: rotate(-10deg);
    }
    10%, 20%, 30% {
        transform: rotate(10deg);
    }
    40%, 100% {
        transform: rotate(0deg);
    }
}

.shake-animation {
    animation: shakeAnimation 3s ease-in-out infinite;
}


@keyframes shakeAnimationLight {
    0%, 100% {
        transform: rotate(0deg);
    }
    5%, 7%, 9%, 11% {
        transform: rotate(-5deg);
    }
    6%, 8%, 10% {
        transform: rotate(5deg);
    }
    12%, 100% {
        transform: rotate(0deg);
    }
}

.shake-animation-light {
    animation: shakeAnimationLight 13s ease-in-out infinite;
}


.tooltip-container {
    border-radius: 16px !important;
    padding: 12px !important;
}

input.no-yellow-autofill:-webkit-autofill,
input.no-yellow-autofill:-webkit-autofill:hover,
input.no-yellow-autofill:-webkit-autofill:focus,
input.no-yellow-autofill:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px black inset !important;
    -webkit-text-fill-color: transparent !important;
    opacity: 0 !important;
    /*display: none !important;*/
}


@keyframes grain {
    0%, 100% {
        background-position: 0 0;
    }
    10% {
        background-position: -5% -10%;
    }
    20% {
        background-position: -15% 5%;
    }
    30% {
        background-position: 7% -25%;
    }
    40% {
        background-position: -20% 20%;
    }
    50% {
        background-position: 15% -15%;
    }
    60% {
        background-position: 0 25%;
    }
    70% {
        background-position: -25% 10%;
    }
    80% {
        background-position: 5% 20%;
    }
    90% {
        background-position: -10% -10%;
    }
}

.grainy-effect::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    pointer-events: none;
    background-image: url('../src/img/noisy-background.jpg');
    opacity: 0.08;
    transform: scale(1);
    background-repeat: repeat;
    animation: grain 0.5s steps(10) infinite;
}


@keyframes slide {
    0%, 100% {
        transform: translateX(-16px) rotate(-80deg);
    }
    50% {
        transform: translateX(32px) rotate(-90deg);
    }
}

.hand-animation {
    animation: slide 3.3s ease-in-out infinite;
}
