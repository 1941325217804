.modal-new-open {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1050 !important;
    top: 0;
    right: 0;
    bottom: 0;
    /*overflow: auto;*/
    /*background-color: #663399B0;*/
    transition: all 150ms ease;
    outline: none !important;
    box-sizing: border-box;
    overflow: hidden;
}

.modal-content {
    background-color: #000000;
    border: 2px solid #00000020;
    padding: 24px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    /*overflow: scroll;*/
    box-shadow: 0 12px 20px #00000020;
}

.modal-content-xs {
    min-width: 300px;
    max-width: 300px;
}

.modal-content-sm {
    min-width: 383px;
    max-width: 383px;
}

.modal-content-md {
    width: 100%;
    max-width: calc(100vw - (100vw - 500px));
    box-sizing: border-box;
}

.modal-content-lg {
    min-width: 800px;
    max-width: 800px;
}

.modal-content-xl {
    width: 1140px;
    max-width: min(1140px, 95vw);
}

.modal-content-full {
    width: 100vw;
    height: 100dvh;
    border-radius: 0 !important;
}

.modal-content-full-mobile {
    width: 100vw;
    min-height: 100dvh;
    padding:12px
}

.close-modal-icon {
    transition: transform 300ms ease-in-out;
    transform: scale(.8);
}

.close-modal-icon:hover {
    transform: rotate(-90deg) scale(1);
}
